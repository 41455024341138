var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loading.list ? _c('SpinnerLogo') : _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('h4', {
    staticClass: "font-w600 mb-1"
  }, [_c('i', {
    staticClass: "fad fa-file-chart-pie text-primary mr-2"
  }), _vm._v("Reports")]), _c('p', {
    staticClass: "mb-3 text-muted font-w500"
  }, [_vm._v("Common reports for your data in Etainabl. Such as consumption, emission and data quality reports.")]), _c('div', {
    staticClass: "mb-4"
  }, [_c('div', {
    staticClass: "row mb-2"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('FormItem', {
    attrs: {
      "id": "system-search",
      "placeholder": "Search..."
    },
    model: {
      value: _vm.search.system,
      callback: function ($$v) {
        _vm.$set(_vm.search, "system", $$v);
      },
      expression: "search.system"
    }
  })], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('FormItem', {
    staticClass: "w-100",
    attrs: {
      "id": "system-sort",
      "type": "select",
      "options": _vm.sortOptions
    },
    model: {
      value: _vm.sort.system,
      callback: function ($$v) {
        _vm.$set(_vm.sort, "system", $$v);
      },
      expression: "sort.system"
    }
  })], 1)]), _vm.groupedTemplates.system.length === 0 ? _c('div', {
    staticClass: "alert alert-warning py-2 px-3"
  }, [_vm._v("No Templates")]) : _c('VerticalPills', {
    attrs: {
      "items": _vm.groupedTemplates.system,
      "item-key": "_id",
      "groups": _vm.groups.system,
      "alt": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "d-flex align-items-center w-100"
        }, [_c('div', {
          staticClass: "flex-grow-1"
        }, [_c('a', {
          staticClass: "nav-link",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.onClickTemplate(item);
            }
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center w-100"
        }, [_c('div', {
          staticClass: "mr-2"
        }, [_c('span', {
          staticClass: "fa-stack text-primary fa-xl",
          staticStyle: {
            "zoom": "0.85"
          }
        }, [_c('i', {
          staticClass: "fa fa-stack-1x",
          class: `${item.icon}`,
          staticStyle: {
            "--fa-stack-z-index": "2",
            "top": "5px"
          }
        }), _c('i', {
          staticClass: "fad fa-file fa-stack-2x",
          staticStyle: {
            "--fa-stack-z-index": "1"
          }
        })])]), _c('div', {
          staticClass: "flex-grow-1"
        }, [_c('div', {
          staticClass: "mb-0"
        }, [_vm._v(_vm._s(item.title))]), _c('div', {
          staticClass: "text-muted font-size-sm font-w500"
        }, [_vm._v(_vm._s(item.description))])])])])]), _c('div', {
          staticClass: "px-2"
        }, [_c('EllipsisDropdown', {
          attrs: {
            "items": _vm.getDropdownItems(item)
          }
        })], 1)])];
      }
    }])
  })], 1), _c('router-link', {
    staticClass: "btn btn-text-secondary btn-block font-w600",
    attrs: {
      "to": {
        name: 'reports-dynamic'
      }
    }
  }, [_c('i', {
    staticClass: "fa mr-1 fa-file-chart-column"
  }), _vm._v(" Create Dynamic Report")])], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('h4', {
    staticClass: "font-w600 mb-1"
  }, [_c('i', {
    staticClass: "fad fa-user text-secondary mr-2"
  }), _vm._v("Your Templates")]), _c('p', {
    staticClass: "mb-3 text-muted font-w500"
  }, [_vm._v(" Templates that you have created from reports and dynamic charts. These templates can also be scheduled. ")]), _c('div', {
    staticClass: "mb-4"
  }, [_c('div', {
    staticClass: "row mb-2"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('FormItem', {
    attrs: {
      "id": "user-search",
      "placeholder": "Search..."
    },
    model: {
      value: _vm.search.user,
      callback: function ($$v) {
        _vm.$set(_vm.search, "user", $$v);
      },
      expression: "search.user"
    }
  })], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('FormItem', {
    staticClass: "w-100",
    attrs: {
      "id": "user-sort",
      "type": "select",
      "options": _vm.sortOptions
    },
    model: {
      value: _vm.sort.user,
      callback: function ($$v) {
        _vm.$set(_vm.sort, "user", $$v);
      },
      expression: "sort.user"
    }
  })], 1)]), _vm.groupedTemplates.user.length === 0 ? _c('div', {
    staticClass: "alert alert-warning py-2 px-3"
  }, [_vm._v("No Templates")]) : _c('VerticalPills', {
    attrs: {
      "items": _vm.groupedTemplates.user,
      "item-key": "_id",
      "groups": _vm.groups.user,
      "alt": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "d-flex align-items-center w-100"
        }, [_c('div', {
          staticClass: "flex-grow-1"
        }, [_c('a', {
          staticClass: "nav-link",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.onClickTemplate(item);
            }
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center w-100"
        }, [_c('div', {
          staticClass: "mr-2"
        }, [_c('i', {
          staticClass: "fad fa-fw fa-2x mr-1 text-secondary",
          class: `${item.icon}`
        })]), _c('div', {
          staticClass: "flex-grow-1"
        }, [_c('div', {
          staticClass: "mb-0"
        }, [_vm._v(_vm._s(item.title))]), _c('div', {
          staticClass: "text-muted font-size-sm font-w500"
        }, [_vm._v(_vm._s(_vm._f("user")(item.userSub, _vm.users)))])])])])]), _c('div', {
          staticClass: "px-2"
        }, [_c('EllipsisDropdown', {
          attrs: {
            "items": _vm.getDropdownItems(item)
          }
        })], 1)])];
      }
    }])
  })], 1)]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('h4', {
    staticClass: "font-w600 mb-1"
  }, [_c('i', {
    staticClass: "fad fa-building text-info mr-2"
  }), _vm._v("Company Templates")]), _c('p', {
    staticClass: "mb-3 text-muted font-w500"
  }, [_vm._v("Templates that have been created by others in your company and made available to you.")]), _c('div', {
    staticClass: "mb-4"
  }, [_c('div', {
    staticClass: "row mb-2"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('FormItem', {
    attrs: {
      "id": "company-search",
      "placeholder": "Search..."
    },
    model: {
      value: _vm.search.company,
      callback: function ($$v) {
        _vm.$set(_vm.search, "company", $$v);
      },
      expression: "search.company"
    }
  })], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('FormItem', {
    staticClass: "w-100",
    attrs: {
      "id": "company-sort",
      "type": "select",
      "options": _vm.sortOptions
    },
    model: {
      value: _vm.sort.company,
      callback: function ($$v) {
        _vm.$set(_vm.sort, "company", $$v);
      },
      expression: "sort.company"
    }
  })], 1)]), _vm.groupedTemplates.company.length === 0 ? _c('div', {
    staticClass: "alert alert-warning py-2 px-3"
  }, [_vm._v("No Templates")]) : _c('VerticalPills', {
    attrs: {
      "items": _vm.groupedTemplates.company,
      "item-key": "_id",
      "groups": _vm.groups.company,
      "alt": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "d-flex align-items-center w-100"
        }, [_c('div', {
          staticClass: "flex-grow-1"
        }, [_c('a', {
          staticClass: "nav-link",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.onClickTemplate(item);
            }
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center w-100"
        }, [_c('div', {
          staticClass: "mr-2"
        }, [_c('i', {
          staticClass: "fad fa-fw fa-2x mr-1 text-info",
          class: `${item.icon}`
        })]), _c('div', {
          staticClass: "flex-grow-1"
        }, [_c('div', {
          staticClass: "mb-0"
        }, [_vm._v(_vm._s(item.title))]), _c('div', {
          staticClass: "text-muted font-size-sm font-w500"
        }, [_vm._v(_vm._s(_vm._f("user")(item.userSub, _vm.users)))])])])])]), _c('div', {
          staticClass: "px-2"
        }, [_c('EllipsisDropdown', {
          attrs: {
            "items": _vm.getDropdownItems(item)
          }
        })], 1)])];
      }
    }])
  })], 1)])]), _c('div', {
    staticClass: "pt-5 pb-5 mb-5"
  }, [_vm._v(" ")]), _c('div', {
    staticClass: "pt-5 pb-5 mb-5"
  }, [_vm._v(" ")]), _vm.selectedTemplateId ? _c('ActionBox', [[_c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.showActionBox = !_vm.showActionBox;
      }
    }
  }, [_c('h5', {
    staticClass: "text-center text-primary mb-2"
  }, [_vm._v("Selected Report "), _c('i', {
    staticClass: "fa fa-chevron-down mr-2"
  })])]), _vm.showActionBox ? _c('div', {
    staticClass: "row justify-content-between align-items-between h-100"
  }, [_c('div', {
    staticClass: "col-md-5"
  }, [_c('p', {
    staticClass: "text-muted text-uppercase font-w600 mb-1"
  }, [_c('i', {
    staticClass: "fa mr-1",
    class: {
      'fa-file-chart-pie': _vm.selectedTemplate.access === 'system',
      'fa-file': _vm.selectedTemplate.access !== 'system'
    }
  }), _vm._v(_vm._s(_vm.selectedTemplate.access === 'system' ? 'Report' : 'Template') + " ")]), _c('h3', {
    staticClass: "mb-1"
  }, [_vm._v(_vm._s(_vm.selectedTemplate.name))]), _c('p', {
    staticClass: "text-muted font-w500 mb-3"
  }, [_vm._v(_vm._s(_vm.selectedTemplate.description))]), _vm.selectedTemplateOriginal ? _c('p', {
    staticClass: "text-muted font-w500 mb-4"
  }, [_vm._v(" This template was created from report: "), _c('strong', [_vm._v(_vm._s(_vm.selectedTemplateOriginal.name))])]) : _vm.selectedTemplate.type === 'dynamic' ? _c('p', {
    staticClass: "text-muted font-w500 mb-4"
  }, [_vm._v("This template was created from a dynamic report.")]) : _vm._e(), _vm.selectedTemplate.access === 'company' ? _c('TextListItem', {
    attrs: {
      "icon": "fa-user",
      "label": "Created By"
    }
  }, [_vm._v(_vm._s(_vm._f("user")(_vm.selectedTemplate.userSub, _vm.users)) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "col-md-7 pl-4 align-self-end"
  }, [_c('div', {
    staticClass: "text-right"
  }, [_c('button', {
    staticClass: "btn btn-primary mr-2",
    on: {
      "click": _vm.onClickTemplate
    }
  }, [_c('i', {
    staticClass: "fa fa-cog mr-2"
  }), _vm._v(" Create Report")]), _vm.selectedTemplate.access !== 'system' ? _c('button', {
    staticClass: "btn btn-text-secondary mr-2",
    on: {
      "click": _vm.onClickSchedule
    }
  }, [_c('i', {
    staticClass: "fa fa-calendar-clock mr-2"
  }), _vm._v(" Schedule ")]) : _vm._e(), _vm.selectedTemplate.access !== 'system' ? _c('button', {
    staticClass: "btn btn-text-secondary mr-2",
    on: {
      "click": _vm.onClickDuplicate
    }
  }, [_c('i', {
    staticClass: "fa fa-copy mr-2"
  }), _vm._v(" Duplicate ")]) : _vm._e(), _vm.selectedTemplate.userSub === _vm.$auth.user.sub ? _c('button', {
    staticClass: "btn btn-text-danger",
    on: {
      "click": function ($event) {
        _vm.modals.delete = _vm.selectedTemplate;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-trash mr-2"
  }), _vm._v("Delete ")]) : _vm._e()])])]) : _vm._e()]], 2) : _vm._e(), _c('Modal', {
    attrs: {
      "open": !!_vm.modals.delete,
      "title": "Delete Template"
    },
    on: {
      "close": function ($event) {
        _vm.modals.delete = false;
      },
      "submit": _vm.onDeleteTemplate
    }
  }, [_c('p', [_vm._v(" Are you sure you want to delete template "), _c('strong', [_vm._v(_vm._s(_vm.modals.delete.name))]), _vm._v("? ")])]), _c('Modal', {
    attrs: {
      "id": "schedule",
      "open": _vm.modals.schedule.show,
      "title": "Schedule Report",
      "text": "Send out this report to users on a regular or one-off basis. You can edit scheduled reports at any time by going to the Reports > Schedule tab once created.",
      "lg-size": "",
      "loading": _vm.modals.schedule.loading
    },
    on: {
      "close": function ($event) {
        _vm.modals.schedule.show = false;
      },
      "submit": _vm.onScheduleReport
    }
  }, [_vm.modals.schedule.show ? _c('ScheduledReportForm', {
    model: {
      value: _vm.modals.schedule.form,
      callback: function ($$v) {
        _vm.$set(_vm.modals.schedule, "form", $$v);
      },
      expression: "modals.schedule.form"
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
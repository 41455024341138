var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.items.length > 0,
      expression: "items.length > 0"
    }],
    ref: "dropdown",
    staticClass: "etn-dropdown",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClick.apply(null, arguments);
      }
    }
  }, [_c('a', _vm._b({
    staticClass: "btn btn-text-secondary",
    attrs: {
      "href": "#"
    }
  }, 'a', _vm.$attrs, false), [_c('i', {
    staticClass: "fa fa-ellipsis"
  })]), _c('div', {
    staticClass: "etn-dropdown-wrapper",
    class: {
      show: _vm.show
    }
  }, [_c('div', {
    ref: "dropdown-menu",
    staticClass: "etn-dropdown-menu rounded-md"
  }, [_c('ul', {
    staticClass: "py-2"
  }, _vm._l(_vm.items, function (item) {
    return _c('li', {
      key: item.name,
      class: {
        danger: item.danger
      }
    }, [_c('a', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickItem(item);
        }
      }
    }, [item.icon ? _c('i', {
      staticClass: "fad font-w500 fa-fw mr-3",
      class: item.icon
    }) : _vm._e(), _vm._v(_vm._s(item.name))])]);
  }), 0)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }